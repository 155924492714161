
// This file is auto generated from package.json
const c = {
  "name": "Skyline",
  "version": "1.13.0",
  "buildDate": "2024-09-09 22:00:44",
  "buildEnv": "qa",
  "buildHash": "146fd08c00faf9a2f990bd0eddc4b46ecf498d0c",
  "isProduction": false,
  "truexServer": "qa-serve.truex.com",
  "mediaServer": "qa-media.truex.com"
};

const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:';
c.protocol = protocol;
c.truexServerUrl = protocol + '//' + c.truexServer;
c.mediaServerUrl = protocol + '//' + c.mediaServer;

export const config = c;
export default config;
